import React from 'react'
// import { useEffect } from 'react'
// import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import '../css/resultsTablesEtc.css'
import InnerHTML from 'dangerously-set-html-content'
import { Link } from 'gatsby';


export default function Tables() {



    


  const html = `  
      <div  id="scoreaxis-widget-a537e" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/8?widgetRows=1%2C1%2C1%2C1%2C1%2C1%2C1%2C1%2C1%2C1&amp;autoHeight=0&amp;inst=a537e" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"a537e"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-a537e iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
      `
  const html2 = `
      <div id="scoreaxis-widget-b48f7" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/564?widgetRows=1%2C1%2C1%2C1%2C1%2C1%2C1%2C1%2C1%2C1&amp;autoHeight=0&amp;inst=b48f7" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"b48f7"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-b48f7 iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
       `
  const html3 = `
      <div id="scoreaxis-widget-92027" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/384?autoHeight=0&amp;inst=92027" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"92027"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-92027 iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
        `
  const html4 = `
      <div id="scoreaxis-widget-1ac3b" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/82?autoHeight=0&amp;inst=1ac3b" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"1ac3b"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-1ac3b iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
          `
  const html5 = `
      <div id="scoreaxis-widget-d421d" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/301?autoHeight=0&amp;inst=d421d" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"d421d"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-d421d iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
              `
  const html6 = `
     <div id="scoreaxis-widget-dae89" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/806?autoHeight=0&amp;inst=dae89" style="width:100%;height:200px;border:none;transition:all 300ms ease"></iframe><script>window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"dae89"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-dae89 iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)});</script></div>
                          `
  
  const html7 = `
     <div id="fs-standings"></div> <script> (function (w,d,s,o,f,js,fjs) { w['fsStandingsEmbed']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'mw', 'https://cdn.footystats.org/embeds/standings.js')); mw('params', { leagueID: 6836 }); </script>
              `
 

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "https://www.scorebar.com/widget/";
  //   script.async = true;
  //   script.setAttribute('data-scorebar', '');
  //   document.getElementById('matchs-details-container').appendChild(script);

  // }, []);

  return (
    <Layout>
    <div>
      <h1 className='py-4 font-semibold dark:text-white'>League Tables</h1>
      <Link to='premierleague/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>ENGLAND</h1></Link>
      <InnerHTML html={html} />

      <Link to='lalaliga/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>SPAIN</h1></Link>
      <InnerHTML html={html2} />
      
      <Link to='seriea/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>ITALY</h1></Link>
      <InnerHTML html={html3} />
      
      <Link to='bundesliga/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>GERMANY</h1></Link>
      <InnerHTML html={html4} />
      
      <Link to='ligue1/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>FRANCE</h1></Link>
      <InnerHTML html={html5} />

      <Link to='sapremierleague/'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>SOUTH AFRICA</h1></Link>
      <InnerHTML html={html6} />
      
      <Link to='#'><h1 className='py-4 text-blue-650 font-semibold dark:text-white'>ZIMBABWE</h1></Link>
      <InnerHTML html={html7} />
      
      
      
    
      {/* <div id="scoreaxis-widget-26dbc" style="border-width:1px;border-color:rgba(0, 0, 0, 0.15);border-style:solid;border-radius:8px;padding:10px;background:rgb(255, 255, 255);width:100%" data-reactroot=""><iframe id="Iframe" src="https://www.scoreaxis.com/widget/standings-widget/8?removeBorders=0&amp;inst=26dbc" style="width:100%;border:none;transition:all 300ms ease"></iframe><script>{window.addEventListener("DOMContentLoaded",event=>{window.addEventListener("message",event=>{if(event.data.appHeight&&"26dbc"==event.data.inst){let container=document.querySelector("#scoreaxis-widget-26dbc iframe");container&&(container.style.height=parseInt(event.data.appHeight)+"px")}},!1)})}</script></div> */}
  
    </div>
    </Layout>
  )
}

